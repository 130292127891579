@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'HyundaiText';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/HyundaiSansTextKRRegular.ttf);
}

@font-face {
  font-family: 'HyundaiText';
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/HyundaiSansTextKRMedium.ttf);
}

@font-face {
  font-family: 'HyundaiText';
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/HyundaiSansTextKRBold.ttf);
}

@font-face {
  font-family: 'HyundaiHead';
  font-style: normal;
  font-weight: 300;
  src: url(../assets/fonts/HyundaiSansHeadKRLight.ttf);
}

@font-face {
  font-family: 'HyundaiHead';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/HyundaiSansHeadKRRegular.ttf);
}

@font-face {
  font-family: 'HyundaiHead';
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/HyundaiSansHeadKRMedium.ttf);
}

@font-face {
  font-family: 'HyundaiHead';
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/HyundaiSansHeadKRBold.ttf);
}

body {
  @apply bg-background m-0;
}
