@import '~react-toastify/dist/ReactToastify.min.css';

.Toastify__toast-container {
  @apply w-full p-0 bottom-0 z-30;
}

.Toastify__toast {
  @apply font-sans rounded-none min-h-14 mb-0 px-4;
}

.Toastify__toast-body {
  @apply text-sm m-0 p-0;
}

.Toastify__toast-theme--colored.Toastify__toast--success,
.Toastify__toast-theme--colored.Toastify__toast--info {
  @apply bg-secondary;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  @apply bg-danger;
}
