@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

.rdrMonthAndYearWrapper {
  @apply hidden;
}

.rdrWeekDays {
  @apply text-sm;
}

.rdrWeekDay {
  @apply text-neutral;
}

.rdrMonth {
  @apply pb-0;
}

.rdrMonthName {
  @apply font-normal text-lg text-black;
}

.rdrDayToday .rdrDayNumber span:after {
  @apply bg-primary;
}

.rdrDay {
  @apply text-sm;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  @apply text-[#1d2429];
}

.rdrInRange {
  @apply bg-[#f9fafb];
}

.rdrStartEdge {
  @apply rounded-l-md;
}

.rdrEndEdge {
  @apply rounded-r-md;
}

.rdrDayStartPreview {
  @apply border-y border-l rounded-l-md;
}

.rdrDayEndPreview {
  @apply border-y border-r rounded-r-md;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  @apply rounded-r-md;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  @apply rounded-l-md;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  @apply border-r rounded-r-md;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  @apply border-l rounded-l-md;
}
