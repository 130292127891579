.bg-simple-pattern {
  background-color: #eee;
  background-image: linear-gradient(#ccc 2px, transparent 2px),
    linear-gradient(90deg, #ccc 2px, transparent 2px), linear-gradient(#ccc 1px, transparent 1px),
    linear-gradient(90deg, #ccc 1px, #eee 1px);
  background-size:
    50px 50px,
    50px 50px,
    10px 10px,
    10px 10px;
  background-position:
    -2px -2px,
    -2px -2px,
    -1px -1px,
    -1px -1px;
}

.border-animate {
  background-image: linear-gradient(90deg, #999 50%, transparent 50%),
    linear-gradient(90deg, #999 50%, transparent 50%),
    linear-gradient(0deg, #999 50%, transparent 50%),
    linear-gradient(0deg, #999 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size:
    15px 2px,
    15px 2px,
    2px 15px,
    2px 15px;
  background-position:
    left top,
    right bottom,
    left bottom,
    right top;
  animation: border-dance 1s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position:
      left top,
      right bottom,
      left bottom,
      right top;
  }

  100% {
    background-position:
      left 15px top,
      right 15px bottom,
      left bottom 15px,
      right top 15px;
  }
}
