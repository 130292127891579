.ReactModal__Overlay {
  @apply transition duration-300 opacity-0;
}

.ReactModal__Overlay--after-open {
  @apply opacity-100;
}

.ReactModal__Overlay--before-close {
  @apply opacity-0;
}

.ReactModal__Content.confirm-modal {
  @apply transition-all duration-300 top-2/3;
}

.ReactModal__Content--after-open.confirm-modal {
  @apply ease-out top-1/2;
}

.ReactModal__Content--before-close.confirm-modal {
  @apply ease-in top-2/3;
}

.ReactModal__Content.action-sheet {
  @apply transition-all duration-300 -bottom-full;
}

.ReactModal__Content--after-open.action-sheet {
  @apply ease-out bottom-0;
}

.ReactModal__Content--before-close.action-sheet {
  @apply ease-in -bottom-full;
}

.modal-open {
  @apply overflow-hidden;
}

.modal-overlay {
  @apply fixed top-0 left-0 z-20 w-screen h-screen bg-gray-700 bg-opacity-50;
}

.confirm-modal {
  @apply absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 min-w-[320px] bg-white overflow-hidden;
}

.action-sheet {
  @apply absolute bottom-0 w-full rounded-t-2xl bg-white overflow-hidden;
}

.scrollable-content {
  @apply overflow-auto;
  max-height: calc(100vh - 160px);
}

.action-sheet .rdrInfiniteMonths {
  max-height: calc(100vh - 320px);
}

.action-sheet .rdrMonth {
  @apply w-full;
}
